import {http} from './config';

export default {
    create:(type) => {
        return http.post('api/v1/estadosCivis', type);
    },
    index:(page=1) => {
        return http.get('api/v1/estadosCivis?page=' + page);
    },
    update:(type) => {
        return http.put('api/v1/estadosCivis/' + type.id, type);
    },
    delete: (id) => {
        return http.delete('api/v1/estadosCivis/' + id);
    },
    list: () => {
        return http.get('api/v1/estadosCivis/listar');
    },
    listPublic: ($companyId) => {
        return http.get('api/v1/publica/estados-civis/listar',{headers:{
            'company-id': $companyId,
            }});
    }
}