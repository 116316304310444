import {http} from './config';
export default {
    index: (page = 1, filter) => {
        return http.get('api/v1/tiposAnexo?page=' + page, {params: filter});
    },
    create: (data) => {
        return http.post('api/v1/tiposAnexo',data);
    },
    delete: (id) => {
        return http.delete(`api/v1/tiposAnexo/${id}`);
    },
    show: (id) => {
        return http.get(`api/v1/tiposAnexo/${id}`);
    },
    list: (vinculed_at = null) => {
        if (vinculed_at){
            return http.get(`api/v1/tiposAnexo/listar?vinculedAt=${vinculed_at}`,{params:{}});
        }
        return http.get(`api/v1/tiposAnexo/listar`);
    },
    listPublic: (companyId, vinculed_at = null) => {
        if (vinculed_at){
            return http.get(`api/v1/publica/tiposAnexo/listar?vinculedAt=${vinculed_at}`,{headers: {'company-id': companyId}});
        }
        return http.get(`api/v1/publica/tiposAnexo/listar`,{headers: {'company-id': companyId}});
    },
    edit: (data) => {
        return http.put(`api/v1/tiposAnexo/${data.id}`, data);
    },
}