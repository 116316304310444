<template>
    <div class="row gutters" v-if="address">
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
            <div class="form-group">
                <label for="cep" :class="isCepRequired ? 'required' : ''">{{ t("COMPANIES.ZIP_CODE") }}: </label>
                <input type="text" id="cep" name="cep" v-model="address.cep" @change="loadCep(address.cep)"
                       class="form-control" placeholder="00000-000" v-mask="'#####-###'" @keyup="inputCep(address.cep)"
                       :disabled="isView"/>
                <div class="validation" id="invalidCep" style="display: none">{{ msgCep }}</div>
                <div v-if="isInvalid?.cep" class="validation">{{ isInvalid.cep }}</div>
                <div v-if="isInvalidComercial?.cep" class="validation">{{ isInvalidComercial.cep }}</div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="form-group">
                <label :class="isCepRequired ? 'required' : ''" for="street">{{ t("COMPANIES.PUBLIC_PLACE") }}: </label>
                <input type="text" id="street" name="street" v-model="address.street" class="form-control"
                       maxlength="80"
                       @keyup="$emit('update', this.address)" :disabled="isView"/>
                <div v-if="isInvalid?.street" class="validation">{{ isInvalid.street }}</div>
                <div v-if="isInvalidComercial?.street" class="validation">{{ isInvalidComercial.street }}</div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
            <div class="form-group">
                <label :class="isCepRequired ? 'required' : ''" for="number">{{ t("COMPANIES.NUMBER") }}: </label>
                <input type="text" id="number" name="number" v-model="address.number" class="form-control"
                       maxlength="10"
                       @keyup="$emit('update', this.address)" :disabled="isView"/>
                <div v-if="isInvalid?.number" class="validation">{{ isInvalid.number }}</div>
                <div v-if="isInvalidComercial?.number" class="validation">{{ isInvalidComercial.number }}</div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="form-group">
                <label for="complement">{{ t("COMPANIES.COMPLEMENT") }}: </label>
                <input type="text" id="complement" name="complement" v-model="address.complement" class="form-control"
                       maxlength="40"
                       @keyup="$emit('update', this.address)" :disabled="isView"/>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="form-group">
                <label :class="isCepRequired ? 'required' : ''"
                       for="neighborhood">{{ t("COMPANIES.NEIGHBORHOOD") }}: </label>
                <input type="text" id="neighborhood" name="neighborhood" v-model="address.neighborhood"
                       class="form-control"
                       maxlength="50"
                       @keyup="$emit('update', this.address)" :disabled="isView"/>
                <div v-if="isInvalid?.neighborhood" class="validation">{{ isInvalid.neighborhood }}</div>
                <div v-if="isInvalidComercial?.neighborhood" class="validation">{{ isInvalidComercial.neighborhood }}</div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
            <div class="form-group">
                <label for="city" :class="isCepRequired ? 'required' : ''">{{ t("ALLOTMENT.CITY") }}: </label>
                <input type="text" id="city" name="city" v-model="address.cityName" class="form-control" disabled/>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
            <div class="form-group">
                <label for="state" :class="isCepRequired ? 'required' : ''">{{ t("ALLOTMENT.STATE") }}: </label>
                <input type="text" id="state" name="state" v-model="address.stateName" class="form-control" disabled/>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import City from '@/services/City.js';
import Validate from '@/components/validate.vue';
import Public from '@/services/Public';
import {useToast} from 'vue-toastification';

export default {
    name: 'addressGeneric',
    mixins: [Validate],
    props: {
        isCompany: {
            type: Boolean,
            default: false,
        },
        loadCepIndex: {
            type: Boolean,
            default: false,
            required: false,
        },
        addressIndex: {
            type: Object,
            required: true
        },
        isCepRequired: {
            type: Boolean,
            default: false
        },
        isView: {
            type: Boolean,
            default: false
        },
        isPublic: {
            type: Boolean,
            default: false
        },
        isInvalid: {
            default: false
        },
        isInvalidComercial: {
            default: false
        },
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            address: null
        }
    },

    mounted() {
        this.address = this.addressIndex;
        if (this.addressIndex.cities) {
            this.address.cityName = this.addressIndex.cities.name;
            this.address.stateName = this.addressIndex.cities.states.name;
        }
    },

    watch: {
        addressIndex: function () {
            this.address = this.addressIndex;
            if (this.addressIndex.cities) {
                this.address.cityName = this.addressIndex.cities.name;
                this.address.stateName = this.addressIndex.cities.states.name;
            }
        },
        loadCepIndex(value) {
            if (value && this.address?.cep) {
                this.loadCep(this.address.cep)
            }
        }
    },

    methods: {
        loadCep(cep) {
            this.$store.commit("changeLoading", true);
            if (!this.isPublic) {
                if (!this.isCompany) {
                    City.listByCepDisp(cep).then(resp => {
                        if (!resp.data.erro && resp.data?.id) {
                            this.address.city_id = resp.data.id;
                            this.address.stateName = resp.data.name;
                            if (this.loadCepIndex && (!this.address?.street && !this.address?.neighborhood)) {
                                this.address.street = resp.data.logradouro;
                                this.address.neighborhood = resp.data.bairro;
                            } else if (!this.loadCepIndex) {
                                this.address.street = resp.data.logradouro;
                                this.address.neighborhood = resp.data.bairro;
                            }
                            this.address.cityName = resp.data.localidade;
                            this.address.complement = resp.data.complemento;
                            this.$emit('update', this.address);
                        }else {
                            this.toast.error('Cep Inválido');
                            this.address.cep = null;
                        }
                        this.$store.commit("changeLoading", false);
                    }).catch(() => {
                        this.toast.error('Cep Inválido');
                        this.address.cep = null;
                        this.$store.commit("changeLoading", false);
                        this.msgCep = this.t('GENERAL.MSG.CEP_INVALID');
                        document.getElementById("cep").classList.add("is-invalid");
                        document.getElementById("invalidCep").style.display = "block";
                    });
                } else {
                    City.listByCep(cep).then(resp => {
                        if (!resp.data.erro && resp.data?.id) {
                            this.address.city_id = resp.data.id;
                            this.address.stateName = resp.data.name;
                            if (this.loadCepIndex && (!this.address?.street && !this.address?.neighborhood)) {
                                this.address.street = resp.data.logradouro;
                                this.address.neighborhood = resp.data.bairro;
                            } else if (!this.loadCepIndex) {
                                this.address.street = resp.data.logradouro;
                                this.address.neighborhood = resp.data.bairro;
                            }
                            this.address.cityName = resp.data.localidade;
                            this.address.complement = resp.data.complemento;
                            this.$emit('update', this.address);
                        }else {
                            this.toast.error('Cep Inválido');
                            this.address.cep = null;
                        }
                        this.$store.commit("changeLoading", false);
                    }).catch(() => {
                        this.toast.error('Cep Inválido');
                        this.address.cep = null;
                        this.$store.commit("changeLoading", false);
                        this.msgCep = this.t('GENERAL.MSG.CEP_INVALID');
                        document.getElementById("cep").classList.add("is-invalid");
                        document.getElementById("invalidCep").style.display = "block";
                    });
                }
            } else {
                if (!this.isCompany) {
                    City.listByCepDisp(cep).then((resp) => {
                        if (!resp.data.erro) {
                            this.address.city_id = resp.data.id;
                            this.address.stateName = resp.data.name;
                            this.address.street = resp.data.logradouro;
                            this.address.cityName = resp.data.localidade;
                            this.address.neighborhood = resp.data.bairro;
                            this.address.complement = resp.data.complemento;

                            this.$emit('update', this.address);
                        }
                        this.$store.commit("changeLoading", false);
                    }).catch(() => {
                        document.getElementById("cep").classList.add("is-invalid");
                        document.getElementById("invalidCep").style.display = "block";
                        this.toast.error(this.t('GENERAL.MSG.CEP_INVALID'));
                    })
                } else {
                    Public.listByCep(cep).then((resp) => {
                        if (!resp.data.erro) {
                            this.address.city_id = resp.data.id;
                            this.address.stateName = resp.data.name;
                            this.address.street = resp.data.logradouro;
                            this.address.cityName = resp.data.localidade;
                            this.address.neighborhood = resp.data.bairro;
                            this.address.complement = resp.data.complemento;

                            this.$emit('update', this.address);
                        }
                        this.$store.commit("changeLoading", false);
                    }).catch(() => {
                        document.getElementById("cep").classList.add("is-invalid");
                        document.getElementById("invalidCep").style.display = "block";
                        this.toast.error(this.t('GENERAL.MSG.CEP_INVALID'));
                    })
                }
            }
        }
    }
}
</script>
