<template>
    <div>
        <div class="mb-2 mt-1">
            <div class="col-12 mb-1">
                <div class="dropdown-divider"></div>
                <div class="text-center font-weight-bolder" style="color: #0e5e4e">
                    <label class="font-14" :class="required ? 'required': ''">
                        <i class="icon-phone"/>{{ t('GENERAL.PHONE') }}s:
                    </label>
                </div>
            </div>
        </div>
        <div>
            <div class="row gutters">
                <div class="col-12">
                    <div
                            class="card border  pt-2 pl-2 pr-2"
                            v-for="(phone, key) in phones"
                            :key="phone.id">
                        <div class="row gutters">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div data-cy="user_index_add_type_phone" class="form-group">
                                    <label
                                            style="font-size: 12px; color: grey"
                                            class="font-weight-bold">{{ t('GENERAL.PHONE_TYPE') }}</label>
                                    <Select2
                                            data-cy="phone_type"
                                            :settings="{width: '100%', placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'um', name: t('GENERAL.TYPE')})}"
                                            :options="types"
                                            v-model="phone.type"
                                            @select="updatePhone"/>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div class="form-group">
                                    <label style="font-size: 12px; color: grey"
                                           class="font-weight-bold">{{ t('COMPANIES.NUMBER') }}</label>
                                    <vue-tel-input
                                            mode="national"
                                            @country-changed="changeCountry($event,key)"
                                            @input="updatePhone"
                                            :autoFormat="true"
                                            :preferredCountries="['US', 'DE', 'FR', 'CN']"
                                            v-model="phone.number"
                                            :defaultCountry="parseInt(phone.code_country)"
                                            :dropdownOptions="dropdownOptions"
                                            :inputOptions="inputOptions"
                                    ></vue-tel-input>
                                </div>
                            </div>
                            <div
                                    class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 d-flex flex-row">
                                <div class="form-group w-100">
                                    <label style="font-size: 12px; color: grey"
                                           class="font-weight-bold">{{ t("GENERAL.COMMENT") }}</label>
                                    <input
                                            class=" form-control"
                                            v-model="phone.description"
                                            type="text"
                                            @change="updatePhone"
                                            maxlength="60">
                                </div>
                                <div
                                        class="ml-2 align-self-center pointer"
                                        v-if="!isView"
                                        @click="deletePhone(key)">
                                    <i
                                            class="icon-x "
                                            style="font-size: 15px"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutters pr-1 pl-1">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div data-cy="user_index_add_type_phone" class="form-group">
                        <label
                                :class="verifyRequired">{{ t('GENERAL.PHONE_TYPE') }}: </label>
                        <Select2 data-cy="phone_type"
                                 :settings="{width: '100%', placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'um', name: t('GENERAL.TYPE')})}"
                                 :options="types"
                                 v-model="type"
                                 @select="alterType"
                                 id="type"
                                 name="type"/>
                        <div
                                class="validation"
                                id="invalidType"
                                style="display: none">
                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="form-group">
                        <label
                                for="phone" :class="verifyRequired">{{ t('COMPANIES.NUMBER') }}: </label>
                        <vue-tel-input
                                mode="national"
                                @input="alterNumber"
                                v-model="number"
                                :autoFormat="true"
                                :preferredCountries="['US', 'DE', 'FR', 'CN']"
                                :defaultCountry="codeNumber"
                                @country-changed="changeCountry"
                                :dropdownOptions="dropdownOptions"
                                :inputOptions="inputOptions"
                        ></vue-tel-input>
                        <div class="validation"
                             id="invalidphone" style="display: none">{{
                                t(msgPhone)
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="form-group">
                        <label>{{ t("GENERAL.COMMENT") }}:</label>
                        <input class="form-control"
                                v-model="description" type="text"
                                @change.stop.prevent="alterDescription"
                                maxlength="60">
                    </div>
                </div>
            </div>
            <div class="row gutters">
                <div class="col-12 d-flex flex-row justify-content-center">
                    <button class="btn btn-outline-primary rounded-pill"
                            @click.prevent.stop="createPhone">
                        <i class="icon-plus"></i> Novo telefone
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
    name: 'phone',
    mixins: [Validate],
    emits: ['cleaned', 'alterDescription', 'alterType', 'alterNumber', 'create', 'update'],
    props: {
        phonesIndex: {
            type: Array,
            defaut: [],
        },
        isView: {
            type: Boolean,
            defaut: false,
        },
        required: {
            type: Boolean,
            defaut: false,
        },
        isInvalid: {
            defaut: false,
        },
        clearDataPhone: {
            defaut: false,
        }
    },
    components: {
        Select2,
        VueTelInput
    },
    computed: {
        verifyRequired() {
            if (this.required && !this.phones?.length) {
                return 'required';
            }
            if (this.required && this.phones?.length) {
                return '';
            }
            if (!this.required) {
                return '';
            }
            return 'required';
        },
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    data() {
        return {
            dropdownOptions: {
                showDialCodeInList: true,
                showDialCodeInSelection: true,
                showFlags: true,
                showSearchBox: true,
                tabindex: 3,
            },
            inputOptions: {
                autocomplete: 'on',
                autofocus: true,
                'aria-describedby': 'des',
                showDialCode: false,
                placeholder: 'Numero Telefone',
                tabindex: 3,
                maxlength: 29,
            },
            codeNumber: 55,
            country_acronym: 'BR',
            add: false,
            type: null,
            phones: [],
            types: [
                {id: 0, text: 'Whatsapp'},
                {id: 1, text: 'Ligação'},
                {id: 2, text: 'Ligação e Whatsapp'}
            ],
            number: '',
            description: null,
        }
    },
    watch: {
        isInvalid: function (val) {
            if (val) {
                document.getElementById('phone')?.classList?.add("is-invalid");
            } else {
                document.getElementById('phone')?.classList?.remove('is-invalid');
            }
        },
        phonesIndex: function () {
            this.add = false;
            this.phones = this.phonesIndex ? this.phonesIndex : [];
        },
        clearDataPhone(val) {
            if (val) {
                this.type = null;
                this.description = null;
                this.number = '';
                this.$emit('cleaned', true);
            }
        }
    },
    created() {
        this.phones = this.phonesIndex ? this.phonesIndex : [];
    },
    methods: {
        changeCountry(val, key = null) {
            if (key){
               this.phones.forEach((i,k) => {
                   if (k === key){
                       i.code_country = val.dialCode;
                       i.country_acronym = val.iso2;
                   }
               });
               this.updatePhone();
               return;
            }
            this.codeNumber = val.dialCode;
            this.country_acronym = val.iso2;
            this.alterNumber();
        },
        alterDescription() {
            this.$emit('alterDescription', this.description)
        },
        alterType() {
            this.$emit('alterType', this.type)
        },
        alterNumber() {
            this.$emit('alterNumber', {number: this.number, code_country: this.codeNumber, country_acronym: this.country_acronym})
        },
        createPhone() {
            this.invalid = [];
            if (this.type == null) {
                this.invalid.push(true);
                document.getElementById("invalidType").style.display = "block";
            } else {
                document.getElementById("invalidType").style.display = "none";
            }
            if (this.invalid.length == 0) {
                this.phones.push({
                    code_country: this.codeNumber,
                    country_acronym: this.country_acronym,
                    type: this.type,
                    number: this.number,
                    description: this.description
                });
                this.type = null;
                this.number = '';
                this.description = null;
                this.$emit('create', this.phones);
            }
        },
        updatePhone() {
            this.$emit('update', this.phones);
        },
        deletePhone(key) {
            this.phones.splice(key, 1);
            this.$emit('update', this.phones);
        },
        validate(input, id) {
            if (input && (input.length == 14 || input.length == 15)) {
                document.getElementById(id).classList.add("is-valid");
                return true;
            } else {
                document.getElementById(id).classList.add("is-invalid");
                return false;
            }
        },
    }
}
</script>

<style scoped>
.font-14 {
    font-size: 14px;
}
::v-deep .vti__dropdown-list  {
    z-index: 4 !important;
}
::v-deep .vti__dropdown-list{
    width: 25vw;
    max-width: 340px;
    max-height: 35vh;
}
@media (max-width: 1199px) {
    ::v-deep .vti__dropdown-list{
        width: 22vw;
        max-width: 230px;
    }
}
@media (max-width: 990px) {
    ::v-deep .vti__dropdown-list{
        width: 24vw;
        max-width: 130px;
    }
}
@media (max-width: 575px) {
    ::v-deep .vti__dropdown-list{
        width: 45vw;
        max-width: 260px;
    }
}
</style>
