<template>
    <div>
        <loadingScreen :isLoading="isLoading"/>
        <div class="main-container">
            <div class="row gutters" :class="this.widthMd(600) ? '': 'pt-3'">
                <div class="col-1" v-if="!this.widthMd(600)"></div>
                <div :class="!this.widthMd(600) ? 'col-10' : 'col-12'">
                    <div class="card">
                        <div class="card-body" :class="!this.widthMd(600) ? '': 'pr-0 pl-0'">
                            <div class="col-12 mb-2">
                                <label for="state">{{ t('NAV.CLIENT') }}</label>
                                <select2 :settings="{ width: '100%' }" id="form_type" name="form_type"
                                         v-model="firstTypePhisical"
                                         :options="['Pessoa Física', 'Pessoa Jurídica']"
                                         @select="selectForm($event)">
                                </select2>
                            </div>
                            <div class="col-12">
                                <form @submit.prevent="create" id="form_create">
                                    <div class="row gutters">
                                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" v-if="isPhisicalPerson">
                                            <div class="form-group">
                                                <label data-cy="client_add_taxpayer" for="taxpayer"
                                                       :class="{'required': isRequiredClassValidation('taxpayer')}"
                                                >CPF: </label>
                                                <input type="text" id="taxpayer" name="taxpayer"
                                                       inputmode="numeric"
                                                       v-model="client.taxpayer" class="form-control"
                                                       @change="findClient(client.taxpayer)"
                                                       placeholder="000.000.000-00"
                                                       v-mask="['###.###.###-##']"
                                                       @keyup="validateTaxpayer(client.taxpayer)">
                                                <div v-if="invalid.taxpayer" class="validation">
                                                    {{ t(invalid.taxpayer) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" v-if="!isPhisicalPerson">
                                            <div class="form-group">
                                                <label data-cy="client_add_taxpayer" for="taxpayer"
                                                       :class="{'required': isRequiredClassValidation('taxpayer')}">CNPJ: </label>
                                                <input type="text" id="taxpayer" name="taxpayer"
                                                       v-model="client.taxpayer" class="form-control"
                                                       @change="findClient(client.taxpayer)"
                                                       placeholder="00.000.000/00000-00"
                                                       v-mask="['##.###.###/####-##']"
                                                       @keyup="validateTaxpayer(client.taxpayer)">
                                                <div v-if="invalid.taxpayer" class="validation">
                                                    {{ t(invalid.taxpayer) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="isPhisicalPerson" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('birthday')}"
                                                       for="birthday">{{ t('CLIENTS.BIRTHDAY_ABBREVIATED') }}: </label>
                                                <input data-cy="client_add_birthday" type="text" id="birthday"
                                                       name="birthday"
                                                       placeholder="dd-mm-aaaa"
                                                       v-mask="['##-##-####']"
                                                       @change="validateDate('birthday')"
                                                       v-model="client.birthday"
                                                       class="form-control"
                                                >
                                                <div v-if="invalid.birthday" class="validation">
                                                    {{ t(invalid.birthday) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('birthday')}"
                                                       for="birthday"
                                                >{{ t('CLIENTS.FOUNDATION_DAY') }}: </label>
                                                <input data-cy="client_add_foundation_day" type="text"
                                                       id="birthday" name="birthday"
                                                       v-model="client.birthday"
                                                       placeholder="dd-mm-aaaa"
                                                       v-mask="['##-##-####']"
                                                       @change="validateDate('birthday')"
                                                       class="form-control"
                                                >
                                                <div v-if="invalid.birthday" class="validation">
                                                    {{ t(invalid.birthday) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="isPhisicalPerson"
                                             class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div class="form-group">
                                                <label for="name"
                                                       class="required"
                                                >{{ t('GENERAL.NAME') }}: </label>
                                                <input data-cy="client_add_name" type="text" id="name" name="name"
                                                       v-model="client.name"
                                                       class="form-control"
                                                       placeholder="Nome Completo">
                                                <div v-if="invalid.name" class="validation">
                                                    {{ t(invalid.name) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label for="name"
                                                       class="required"
                                                >{{
                                                        t('COMPANIES.NAME')
                                                    }}: </label>
                                                <input type="text" id="name" name="name"
                                                       v-model="client.corporate_name"
                                                       class="form-control" placeholder="Razão Social"
                                                >
                                                <div v-if="invalid.name" class="validation">
                                                    {{ t(invalid.name) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="isPhisicalPerson" class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('sex')}"
                                                       for="sex">{{ t('GENERAL.SEX') }}: </label>
                                                <Select2 :settings="{ width: '100%' }" id="sex" name="sex"
                                                         v-model="client.sex" placeholder="Gênero"
                                                         :options="options"/>
                                                <div v-if="invalid.sex" class="validation">
                                                    {{ t(invalid.sex) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="!isPhisicalPerson"
                                             class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('trade_name')}"
                                                       for="trade_name">{{ t('CLIENTS.TRADE_NAME') }}: </label>
                                                <input type="text" id="trade_name" name="trade_name"
                                                       v-model="client.trade_name" placeholder="Nome Fantasia"
                                                       class="form-control">
                                                <div v-if="invalid.trade_name" class="validation">
                                                    {{ t(invalid.trade_name) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="isPhisicalPerson"
                                             class="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('rg')}"
                                                       for="rg">RG: </label>
                                                <input type="text" id="rg" name="rg" v-model="client.rg"
                                                       class="form-control" maxlength="15" placeholder="RG">
                                                <div v-if="invalid.rg" class="validation">
                                                    {{ t(invalid.rg) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="isPhisicalPerson" class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('dispatching_agency')}"
                                                       for="dispatching_agency">{{
                                                        t('CLIENTS.DISPATCH_AGENCY')
                                                    }}: </label>
                                                <input type="text" id="dispatching_agency" name="dispatching_agency"
                                                       placeholder="Orgão Expedidor"
                                                       v-model="client.dispatching_agency" class="form-control">
                                                <div v-if="invalid.dispatching_agency" class="validation">
                                                    {{ t(invalid.dispatching_agency) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="isPhisicalPerson" class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('dispatch_date')}"
                                                       for="dispatch_date">{{ t('CLIENTS.DISPATCH_DATE') }}: </label>
                                                <input class="form-control" type="text" id="dispatch_date"
                                                       name="dispatch_date"
                                                       placeholder="dd-mm-aaaa"
                                                       v-mask="['##-##-####']"
                                                       @change="validateDate('dispatch_date')"
                                                       v-model="client.dispatch_date">
                                                <div v-if="invalid.dispatch_date" class="validation">
                                                    {{ t(invalid.dispatch_date) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="isPhisicalPerson" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <label :class="{'required': isRequiredClassValidation('profession_id')}"
                                                   for="profession_id">{{ t('CLIENTS.PROFESSION') }}: </label>
                                            <Select2 :settings="setting" @select="inputProfession(client.profession_id)"
                                                     data-cy="client_add_profession" id="profession_id"
                                                     name="profession_id" placeholder="Profissão"
                                                     v-model="client.profession_id"
                                            ></Select2>
                                            <div v-if="invalid.profession_id" class="validation">
                                                {{ t(invalid.profession_id) }}
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('email')}"
                                                       for="email">{{ t('GENERAL.EMAIL') }}: </label>
                                                <input data-cy="client_add_email" type="text" id="email" name="email"
                                                       v-model="client.email"
                                                       class="form-control"
                                                       placeholder="exemplo@exem.com">
                                                <div v-if="invalid.email" class="validation">
                                                    {{ t(invalid.email) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="!isPhisicalPerson"
                                             class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label>{{ t('GENERAL.RESPONSABLE') }}: </label>
                                                <select2 v-if="client?.responsableName"
                                                         :settings="ajaxResponsable"
                                                         :placeholder="client.responsableName"
                                                         @select="selectedResponsableId"
                                                ></select2>
                                                <select2 v-else
                                                         :settings="ajaxResponsable"
                                                         placeholder="Selecione"
                                                         @select="selectedResponsableId">
                                                </select2>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="isPhisicalPerson" class="row gutters">
                                        <div v-if="isPhisicalPerson" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('marital_status_id')}"
                                                >{{ t('CLIENTS.MARITAL_STATUS') }}: </label>
                                                <Select2 :settings="maritalstatus"
                                                         @select="selectMaritalStatus($event)"
                                                         :options="[{id: 'selecione', text: 'selecione'}, ...maritalStatusesOptions]"
                                                         placeholder="Estado Civil"
                                                         id="marital_status_id" name="marital_status_id"
                                                         v-model="client.marital_status_id"
                                                ></Select2>
                                            </div>
                                            <div v-if="invalid.marital_status_id" class="validation">
                                                {{ t(invalid.marital_status_id) }}
                                            </div>
                                        </div>
                                        <div v-if="isPhisicalPerson" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                                             id="spouse_block">
                                            <div class="form-group">
                                                <label>{{ t('CLIENTS.CONJUGE') }}: </label>
                                                <Select2 :settings="settingSpouse"
                                                         @select="selectSpouse"
                                                         :placeholder="client.spouseName ? client.spouseName : 'Selecione'"
                                                         id="spouse_id" name="spouse_id"
                                                         v-model="client.spouse_id"
                                                ></Select2>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('naturalness')}"
                                                       for="naturalness">{{
                                                        t('CLIENTS.BIRTH_PLACE')
                                                    }}: </label>
                                                <input type="text" id="naturalness" name="naturalness"
                                                       placeholder="Naturalidade"
                                                       v-model="client.naturalness"
                                                       class="form-control"
                                                >
                                                <div v-if="invalid.naturalness" class="validation">
                                                    {{ t(invalid.naturalness) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('nationality')}"
                                                       for="nationality">{{
                                                        t('CLIENTS.NATIONALITY')
                                                    }}: </label>
                                                <input type="text" id="nationality" name="nationality"
                                                       v-model="client.nationality" placeholder="Nacionalidade"
                                                       class="form-control"
                                                >
                                                <div v-if="invalid.nationality" class="validation">
                                                    {{ t(invalid.nationality) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="isPhisicalPerson" class="row gutters">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('mother_name')}"
                                                       for="mother_name">{{
                                                        t('CLIENTS.MOTHER_NAME')
                                                    }}: </label>
                                                <input type="text" id="mother_name" name="mother_name"
                                                       v-model="client.mother_name" placeholder="Nome da Mãe"
                                                       class="form-control"
                                                >
                                                <div v-if="invalid.mother_name" class="validation">
                                                    {{ t(invalid.mother_name) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label :class="{'required': isRequiredClassValidation('father_name')}"
                                                       for="father_name">{{
                                                        t('CLIENTS.FATHER_NAME')
                                                    }}: </label>
                                                <input type="text" id="father_name" name="father_name"
                                                       v-model="client.father_name" placeholder="Nome do Pai"
                                                       class="form-control"
                                                >
                                                <div v-if="invalid.father_name" class="validation">
                                                    {{ t(invalid.father_name) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row gutters">
                                        <div class="col-12 mb-1">
                                            <div class="dropdown-divider"></div>
                                            <div class="text-center font-weight-bolder" style="color: #0e5e4e">
                                                <label class="font-14">
                                                    <i class="icon-location_city"/>{{ t('CLIENTS.ADDRESS') }}:
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12">
                                            <address-generic :addressIndex="address" :isPublic="true"
                                                             :is-invalid="this.invalid.address"
                                                             :is-cep-required="isRequiredClassValidation('address_id')"
                                                             @update="updateAddress($event)"/>
                                        </div>
                                    </div>
                                    <div class="row gutters"></div>
                                    <div v-if="hasPlanDrive" class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12 p-0">
                                        <div class="col-12 mb-2 mt-1">
                                            <div class="dropdown-divider"></div>
                                            <div class="text-center font-weight-bolder" style="color: #0e5e4e">
                                                <label class="font-14">
                                                    <i class="icon-attach_file"/>Anexar Documentos:
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 p-0">
                                            <div class="card w-100 ">
                                                <div class="card-body row gutters">
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                                        <label class="required">{{ t('CLIENTS.TYPE_ANEX') }}: </label>
                                                        <Select2 :settings="{width: '100%'}" :options="optionsDriveType"
                                                                 placeholder="Selecione um Tipo de Anexo"
                                                                 id="typeAnex" name="typeAnex"
                                                                 v-model="anex.drive_files_type_id"
                                                        ></Select2>
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex pt-xl-0 pt-lg-0 pt-md-0 pt-sm-2 pt-2">
                                                        <button class="btn btn-primary  ml-3 align-self-end"
                                                                @click.prevent.stop="setFile" id="new_anex"
                                                                :disabled="!anex.drive_files_type_id">
                                                            <i class="icon-file_upload"></i> Selecionar Anexo
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="border rounded p-2 card" v-if="client?.documents?.length">
                                                <label class="font-14 ml-2 mt-2 mb-3"><i
                                                        class="icon-attach_file mr-1"></i>
                                                    Anexos:
                                                </label>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row"
                                                     v-if="!this.widthMd(850)"
                                                >
                                                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 font-bold pl-4">
                                                        <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                                                this.t('CLIENTS.DOCUMENTS.NAME')
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 font-bold">
                                                        <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                                                this.t('CLIENTS.TYPE_ANEX')
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 font-bold">
                                                        <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                                                this.t('CLIENTS.DOCUMENTS.SIZE')
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <template v-if="!this.widthMd(850)">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row p-2 w-100"
                                                         v-for="(document, key) in client.documents" :key="key"
                                                         :class="key != 0 ? 'border-top2': ''">
                                                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                                            <div class="list-primary  text-left">
                                                                {{ document.name }}
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                            <Select2 :settings="{width: '100%'}"
                                                                     :options="optionsDriveType"
                                                                     placeholder="Selecione um Tipo de Anexo"
                                                                     disabled
                                                                     :id="`typeAnex${key}`" :name="`typeAnex${key}`"
                                                                     v-model="document.drive_files_type_id"
                                                            ></Select2>
                                                        </div>
                                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                            <div class="list-primary  text-left">
                                                                {{ formatSize(document.file_size) }}
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex">
                                                            <div class="list-primary pointer">
                                                                <i class="icon-trash-2 pl-2"
                                                                   @click="documentDelete(document, key)"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="this.widthMd(850)">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row p-2 w-100"
                                                         v-for="(document, key) in client.documents" :key="key"
                                                         :class="key != 0 ? 'border-top2': ''">
                                                        <div class="col-12 d-flex flex-row">
                                                            <p class="font-weight-bolder mr-2">{{
                                                                    this.t('CLIENTS.DOCUMENTS.NAME')
                                                                }}:
                                                            </p> {{ document.name }}
                                                        </div>
                                                        <div class="col-12 d-flex flex-row">
                                                            <p class="font-weight-bolder mr-2"
                                                               style="min-width: max-content">{{
                                                                    this.t('CLIENTS.TYPE_ANEX')
                                                                }}:
                                                            </p>
                                                            <div style="min-width: max-content" class="w-100">
                                                                <Select2 :settings="{width: '100%'}"
                                                                         :options="optionsDriveType"
                                                                         placeholder="Selecione um Tipo de Anexo"
                                                                         disabled
                                                                         :id="`typeAnex${key}`" :name="`typeAnex${key}`"
                                                                         v-model="document.drive_files_type_id"
                                                                ></Select2>
                                                            </div>

                                                        </div>
                                                        <div class="col-12 d-flex flex-row">
                                                            <p class="font-weight-bolder mr-2">{{
                                                                    this.t('CLIENTS.DOCUMENTS.SIZE')
                                                                }}
                                                            </p>
                                                            <p>{{ formatSize(document.file_size) }}</p>
                                                        </div>
                                                        <div class="col-12 text-right">
                                                            <div class=" pointer text-right">
                                                                <i class="icon-trash-2 pl-2" style="font-size: 17px"
                                                                   @click="documentDelete(document, key)"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="padding: 0">
                                        <phoneComponentInline :required="isRequiredClassValidation('phones')"
                                                              :phonesIndex="client.phones"
                                                              @alterDescription="alterDescription"
                                                              @alterType="alterType"
                                                              @alterNumber="alterNumber"
                                                              :isInvalid="invalid.phones"
                                                              :clear-data-phone="clearDataPhone"
                                                              @cleaned="cleanedPhone"
                                                              @update="updatePhone($event)"
                                                              @create="createPhone($event)"
                                        ></phoneComponentInline>
                                    </div>
                                </form>
                                <div class="row gutters">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                        <div class="form-group">
                                            <label
                                                    :class="{'required': isRequiredClassValidation('description')}"
                                            >Observação: </label>
                                            <editor :init="configTinyMce" v-model="client.description"
                                                    id="description"
                                                    @update:modelValue="summernoteChange($event)"
                                                    tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js"
                                            >
                                            </editor>
                                            <div v-if="invalid.description" class="validation">{{
                                                    invalid.description
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3 mb-3">
                                    <button data-cy="client_add_save_public" class="btn btn-primary mr-3"
                                            @click="create" type="submit">
                                        {{ t('ACTIONS.SAVE') }}
                                    </button>
                                    <button data-cy="client_clear_form_public" class="btn btn-primary"
                                            @click="clearForm" type="submit">
                                        {{ t('ACTIONS.CLEAR') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
                <div class="col-1" v-if="!this.widthMd(600)"></div>
            </div>
        </div>
        <upload-modal :uploadFile="uploadFile" @upload="uploadFile = $event" :absolutePosition="true"
                      @import="uploadDocument($event)"></upload-modal>
    </div>
</template>

<script>

import LoadingScreen from '@/components/layouts/loadScreenComponent';
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import 'vue-advanced-cropper/dist/style.css';
import Select2 from 'vue3-select2-component'
import Public from "@/services/Public";
import AddressGeneric from '@/components/layouts/addressComponent.vue';
import moment from "moment";
import Drive from "../../services/Drive";
import DriveFilesType from '../../services/DriveFilesType';
import uploadModal from '../../components/layouts/uploadModal';
import AttibutesValidations from '../../services/AttibutesValidations';
import AttributesValidationsMixin from '../../mixins/AttributesValidationsMixin';
import {mapState} from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import phoneComponentInline from '../../components/layouts/phoneComponentInline';
import widthMd from '../../mixins/widthMd';
import maritalStatuses from '../../services/maritalStatuses';
import City from '../../services/City';
import Seller from '../../services/Seller';
import Clients from '../../services/Clients';

export default {
    name: 'publicClient',
    components: {
        LoadingScreen,
        Select2,
        AddressGeneric,
        uploadModal,
        Editor,
        phoneComponentInline,
    },
    mixins: [Validate, AttributesValidationsMixin, widthMd],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    computed: {
        ...mapState({
            itemsValidations: 'itemsValidations',
            itemsValidationsRequired: 'itemsValidationsRequired',
        }),
    },

    data() {
        return {
            settingSpouse: {
                width: '100%',
                language: {
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    },
                    searching: function () {
                        return "Buscando...";
                    }
                },
                dropdownParent: '#spouse_block',
                minimumInputLength: 0,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/clientes/list?spouse=true`,
                    dataType: "json",
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                    },
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                },
                data: [
                    {id: 0, text: "Nenhum"}
                ],
                dropdownCssClass: 'select2-dropdown-with-none',
            },
            clearDataPhone: false,
            type: null,
            number: null,
            codeNumber: 55,
            country_acronym: 'BR',
            description: null,
            invalid: {},
            configTinyMce: {
                selector: 'textarea',
                license_key: 'gpl',
                contextmenu: false,
                plugins: 'print preview searchreplace autolink autosave save directionality visualchars fullscreen link charmap hr pagebreak toc advlist lists wordcount textpattern emoticons',
                menubar: 'edit format',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | media template link anchor codesample | ltr rtl',
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 300,
                content_css: 'default',
                language: 'pt_BR',
                images_upload_url: '',
                setup: function (editor) {
                    editor.on('Paste', function (e) {
                        var items = e.clipboardData.items;
                        for (var i = 0; i < items.length; i++) {
                            if (items[i].type.indexOf('image/') !== -1) {
                                e.preventDefault();
                                alert('Colar imagens não é permitido.');
                                return;
                            }
                        }
                    });
                    editor.on('drop', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        alert('Arrastar e soltar de imagens não é permitido.');
                    });
                },
                file_picker_callback: function () {
                    return false;
                },
                images_upload_handler: function (blobInfo, success, failure) {
                    failure('Upload de imagem desabilitado');
                },
                image_advtab: false,
                image_title: false,
                automatic_uploads: false,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            },
            uploadFile: false,
            optionsDriveType: [],
            optionsDriveTypeRequired: [],
            anex: {},
            isLoading: true,
            client: {},
            address: {},
            hasPlanDrive: null,
            activeTab: 0,
            fileDescription: null,
            file: {},
            optionsMaritalStatus: [
                "Solteiro(a)",
                "Casado(a)",
                "Separado(a)",
                "Divorciado(a)",
                "Viúvo(a)",
                "Amasiado(a)",
                "Separado(a) Judicialmente",
                "União estável",
                "Casado(a) no regime de comunhão parcial de bens",
                "Casado(a) no regime de comunhão universal de bens (Antes lei 6.515/77)",
                "Casado(a) no regime de comunhão universal de bens (Após lei 6.515/77)",
                "Casado(a) no regime de separação total de bens",
                "Casado(a) no regime de participação final nos aquestos"
            ],
            firstTypePhisical: "",
            options: ['MASCULINO', 'FEMININO', 'OUTROS'],
            isTaxpayerInvalid: false,
            isBirthdayInvalid: false,
            isNameInvalid: false,
            isPhisicalPerson: true,
            setting: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 3 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                minimumInputLength: 3,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/publica/profissoes/listar`,
                    dataType: "json",
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                }
            },
            maritalStatusesOptions: [],
            maritalstatus: {
                width: '100%',
            },
            ajaxResponsable: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 1 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/publica/clientes/list?onlyResponsable=true`,
                    dataType: "json",
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    headers: {
                        'company-id': this.$route.params.companyId,
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                }
            },
        }
    },

    mounted() {
        this.loadMaritalStatus();
        this.getAttributesValidations('clients_phisical')
        this.listFilesType();
        this.checkUrlContinue();
        this.isLoading = false;
        this.firstTypePhisical = "Pessoa Física";
        this.address = {
            cep: null,
            complement: null,
            neighborhood: null,
            number: null,
            street: null,
        }
        this.client.nationality = "Brasileiro(a)";
        this.verifyDrivePermission();
    },

    methods: {
        selectSpouse({id, text}) {
            if (!id) {
                this.client.spouse_id = null;
                this.client.spouseName = null;
            } else {
                this.client.spouse_id = id;
                this.client.spouseName = text;
            }
        },
        selectMaritalStatus(m) {
            if (m.id === 'selecione') {
                this.client.marital_status_id = null;
            }
        },
        loadMaritalStatus() {
            maritalStatuses.listPublic(this.$route.params.companyId).then(r => {
                this.maritalStatusesOptions = r.data;
            });
        },
        cleanedPhone() {
            this.clearDataPhone = false;
        },
        alterDescription(val) {
            this.description = val;
        },
        alterType(val) {
            this.type = val;
        },
        alterNumber(val) {
            this.number = val.number;
            this.codeNumber = val.code_country;
            this.country_acronym = val.country_acronym;
        },
        createPhone(phones) {
            this.description = null;
            this.type = null;
            this.number = null;
            this.client.phones = phones;
        },
        validateDate(context) {
            if (context === 'birthday') {
                let valid = moment(this.client.birthday, 'DD-MM-YYYY').isValid();
                if (!valid) {
                    this.client.birthday = null;
                    this.invalid.birthday = 'Data Inválida'
                } else {
                    this.invalid.birthday = null;
                }
            } else if (context === 'dispatch_date') {
                let valid = moment(this.client.dispatch_date, 'DD-MM-YYYY').isValid();
                if (!valid) {
                    this.client.dispatch_date = null;
                    this.invalid.dispatch_date = 'Data Inválida'
                } else {
                    this.invalid.dispatch_date = null;
                }
            }
        },
        summernoteChange(newValue) {
            this.client.description = newValue;
        },
        selectedResponsableId({id, text}) {
            if (!id) {
                this.client.responsible_id = null;
                this.client.responsableName = null;
            } else {
                this.client.responsible_id = id;
                this.client.responsableName = text;
            }
        },
        async getAttributesValidations(context) {
            this.$store.commit('changeLoading', true);
            await AttibutesValidations.publicGetByContext(context, this.$route.params.companyId).then(resp => {
                this.$store.commit('setItemsValidations', resp.data);
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        verifyDrivePermission() {
            Drive.hasPlanDrive(this.$route.params.companyId).then(res => {
                this.hasPlanDrive = res.data.hasPlan;
            })
        },
        documentDelete(anex, key) {
            if (anex.id) {
                this.$store.commit('changeLoading', true);
                Drive.fileDelete(anex.id).then(() => {
                    this.client.documents = this.client.documents.filter(document => {
                        if (document.id !== anex.id) return document;
                    })
                    if (!this.client.documents.length) this.activeTab--;
                    this.toast.success(this.t('CLIENTS.DOCUMENTS.DELETE_SUCCESSFULL'));
                    this.$store.commit('changeLoading', false);
                }).catch(err => {
                    this.errorMsg(err);
                    this.$store.commit('changeLoading', false);
                });
            } else {
                this.client.documents = this.client.documents.filter((document, k) => {
                    if (k !== key) return document;
                })
            }
        },
        formatSize(fileSize) {
            if (fileSize < 1000) return `${fileSize} Bytes`
            if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`
            if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`
            if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`
        },
        setFile() {
            this.uploadFile = true;
        },
        async uploadDocument(file) {
            if (!file) return this.toast.error(this.t('CLIENTS.DOCUMENTS.FILE_EMPTY'));
            if (!this.anex.drive_files_type_id) return this.toast.error('Obrigatorio selecionar Tipo de Anexo');
            if (!this.client?.documents?.length) {
                this.client.documents = [];
            }
            await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
            }).then(base64 => {
                this.client.documents.push({
                    drive_files_type_id: this.anex.drive_files_type_id,
                    name: file.name,
                    file_size: file.size,
                    file: base64,
                    context_id: null,
                    context: 'clientes'
                })
            });
            this.anex = {};
        },
        listFilesType() {
            this.optionsDriveType = [];
            this.optionsDriveTypeRequired = [];
            let vinculed_at = this.isPhisicalPerson ? 'fisica' : 'juridica';
            DriveFilesType.listPublic(this.$route.params.companyId, vinculed_at).then((resp) => {
                if (resp?.data?.length) {
                    resp.data.forEach((i) => {
                        if (i.required_on_person && this.isPhisicalPerson) {
                            this.optionsDriveTypeRequired.push(i);
                            this.optionsDriveType.push({id: i.id, text: `${i.text} *`});
                        }else if(!this.isPhisicalPerson && i.required_on_person_juridical){
                            this.optionsDriveTypeRequired.push(i);
                            this.optionsDriveType.push({id: i.id, text: `${i.text} *`});
                        } else {
                            this.optionsDriveType.push({id: i.id, text: i.text});
                        }
                    })
                }
            })
        },
        checkUrlContinue() {
            Public.checkUserIdAndCompanyIdExists(
                    {
                        companyId: this.$route.params.companyId,
                        usuarioId: this.$route.params.userId
                    }).then(resp => {
                if (!resp?.data?.continue) {
                    this.toast.error('URL Invalida');
                    this.$router.push({name: 'Home'});
                }
            }).catch(() => {
                this.$router.push({name: 'Home'});
            });
        },
        chooseFile(e) {
            let filename = ''
            let targetFiles = e.target.files;
            for (let file of targetFiles) {
                filename = filename + file.name + '; '
            }
            this.file = {'fileName': filename, ...targetFiles}
        },
        create() {
            let companyId = this.$route.params.companyId;
            this.client.created_by = this.$route.params.userId;
            this.client.company_id = companyId;

            this.isLoading = true;
            if (this.client.profession_id === "0") {
                this.client.profession_id = null;
            }

            if (this.client.corporate_name) {
                this.client.name = this.client.corporate_name
            }
            this.isLoading = true;

            if (this.optionsDriveTypeRequired?.length) {
                let invalidDrive = false;
                this.optionsDriveTypeRequired.forEach(i => {
                    let has = false;
                    this.client?.documents?.forEach(d => {
                        if (parseInt(d.drive_files_type_id) === i.id) {
                            has = true;
                        }
                    });
                    if (!has) {
                        this.toast.error(`Obrigatorio informar um Anexo do tipo: ${i.text}`);
                        invalidDrive = true;
                    }
                });
                if (invalidDrive) {
                    this.isLoading = false;
                    return;
                }
            }

            if (this.checkForm()) {
                let key = process.env.VUE_APP_KEY_TO_PUBLIC_ACCESS;
                if (this.isPhisicalPerson) {
                    this.client.type = 'phisical';
                } else this.client.type = 'juridical';
                if (!this.client?.address?.city_id) {
                    this.client.address = {};
                }
                if (!this.client?.address?.city_id) {
                    this.client.address = {};
                }
                this.$store.commit('changeLoading', true);
                Public.CreateClient(this.client, companyId, key).then(async (resp) => {
                    if (resp.data.status === 401) {
                        this.toast.error(this.t("GENERAL.MSG.YOU_DONT_HAVE_PERMISSON"));
                        this.$router.push({name: 'Login'});
                    } else {
                        if (this.file.fileName && this.hasPlanDrive) {
                            this.$store.commit('changeLoading', true);
                            let formData = new FormData();
                            formData.append('file', this.file[0]);
                            formData.append('context_id', resp.data.id);
                            formData.append('context', 'clientes');
                            formData.append('description', this.fileDescription);
                            await Drive.uploadDocumentPublic(formData, companyId).then((resp) => {
                                if (resp.data.code) {
                                    this.toast.success(resp.data.description);
                                } else {
                                    this.toast.success(this.t('CLIENTS.DOCUMENTS.CREATED'));
                                }
                                document.getElementById('inputFileDocument').value = null;
                                this.file = [];
                                this.fileDescription = null;
                                this.$store.commit('changeLoading', false);
                            }).catch(err => {
                                this.errorMsg(err);
                                this.$store.commit('changeLoading', false);
                            }).finally(() => {
                                this.$store.commit('changeLoading', false)
                            })
                        }
                        this.isLoading = false;
                        this.toast.success(this.t("GENERAL.MSG.REGISTER_SUCCESS"));
                        this.client = {};
                        this.address = {};
                    }
                    this.$store.commit('changeLoading', false);
                }).catch(error => {
                    this.$store.commit('changeLoading', false);
                    this.isLoading = false;
                    this.errorMsg(error)
                });
            } else {
                this.isLoading = false;
            }
        },

        async selectForm(value) {
            this.isPhisicalPerson = value.text !== 'Pessoa Jurídica';
            this.listFilesType();
            if (this.isPhisicalPerson) {
                await this.getAttributesValidations('clients_phisical');
            } else {
                await this.getAttributesValidations('clients_juridical');
            }
        },

        inputProfession(profession) {
            if (profession) {
                this.client.profession_id = profession;
            }
        },

        updateAddress(address) {
            this.isLoading = true;
            this.client.address = address;
            this.isLoading = false;
        },

        updatePhone(phones) {
            this.client.phones = phones;
        },

        findClient(taxpayer) {
            if (this.isPhisicalPerson && taxpayer?.length !== 14) {
                return;
            } else if (taxpayer?.length !== 18) {
                return;
            }
            let tax = btoa(taxpayer);
            this.$store.commit('changeLoading', true);
            Public.showClient(tax, this.$route.params.companyId).then(async (res) => {
                if (res?.data?.validations && !this.isPhisicalPerson){
                    taxpayer = taxpayer.replaceAll(".", "");
                    taxpayer = taxpayer.replaceAll("-", "");
                    taxpayer = taxpayer.replaceAll("/", "");
                    await Seller.loadCnpj(taxpayer).then(async (resp) => {
                        this.client.corporate_name = resp.data.razao_social_nome_empresarial;
                        this.client.email = resp.data.estabelecimento[0]?.correio_eletronico;
                        this.client.birthday = this.formaterBirthDay(resp.data.estabelecimento[0]?.data_inicio_atividades);
                        this.client.trade_name = resp.data.estabelecimento[0]?.nome_fantasia;
                        this.address.street = resp.data.estabelecimento[0]?.logradouro;
                        this.address.stateName = resp.data.estabelecimento[0]?.uf;
                        this.address.cep = resp.data.estabelecimento[0]?.cep;
                        this.address.neighborhood = resp.data.estabelecimento[0]?.bairro;
                        this.address.complement = resp.data.estabelecimento[0]?.complemento;
                        this.address.cityName = resp.data.estabelecimento[0]?.municipio.descricao;
                        this.address.number = resp.data.estabelecimento[0]?.numero;
                        if (this?.address?.cep) {
                            await City.listByCepDisp(this.address.cep).then(async (resp) => {
                                if (!resp.data.erro) {
                                    this.address.city_id = resp.data.id;
                                    this.address.stateName = resp.data.name;
                                    this.address.cityName = resp.data.localidade;
                                    this.$emit('update', this.address);
                                }
                            })
                            this.client.address = this.address;
                            if (!this.client?.address?.city_id) {
                                this.client.address = {};
                                this.address = {};
                            }
                        } else {
                            this.client.address = {};
                            this.address = {};
                        }
                        if (await this.checkNumberPhone(`${resp.data.estabelecimento[0]?.ddd1}${resp.data.estabelecimento[0]?.telefone1}`)) {
                            this.client.phones = [{
                                number: (`(${resp.data.estabelecimento[0].ddd1}) ` + resp.data.estabelecimento[0].telefone1),
                                type: '2'
                            }];
                        } else {
                            this.client.phones = [{
                                number: (`(${resp.data.estabelecimento[0]?.ddd1}) ` + resp.data.estabelecimento[0]?.telefone1),
                                type: '1'
                            }];
                        }
                        if (resp.data.estabelecimento[0]?.ddd2) {
                            if (await this.checkNumberPhone(`${resp.data.estabelecimento[0]?.ddd2}${resp.data.estabelecimento[0]?.telefone2}`)) {
                                this.client.phones.push({
                                    number: (`(${resp.data.estabelecimento[0]?.ddd2}) ` + resp.data.estabelecimento[0]?.telefone2),
                                    type: '2'
                                });
                            } else {
                                this.client.phones.push({
                                    number: (`(${resp.data.estabelecimento[0]?.ddd2}) ` + resp.data.estabelecimento[0]?.telefone2),
                                    type: '1'
                                });
                            }
                        }
                        this.$store.commit("changeLoading", false);
                    }).catch(() => {
                        this.$store.commit("changeLoading", false)
                    });
                }else if (res.data.length !== 0) {
                    if (res.data.company_id === this.$route.params.companyId) {
                        this.$store.commit("changeLoading", false)
                        this.toast.error(this.t("GENERAL.MSG.CLIENT_ALREADY_IN_COMPANY_WITHOUT_UPDATE"), {timeout: false});
                    }
                }
                this.$store.commit("changeLoading", false)
            }).catch(() => {
                this.$store.commit("changeLoading", false)
            })
        },
        async checkNumberPhone(number) {
            let isValid = false
            await Clients.numberValidPublic(number).then(response => {
                isValid = response.data.numberExists;
            })
            return isValid;
        },
        formaterBirthDay(date) {
            if (!date) {
                return null;
            }
            date = date.split('/');
            return `${date[0]}-${date[1]}-${date[2]}`;
            //  return `${date[2]}-${date[1]}-${date[0]}`;
        },
        isRequired(field) {
            return !!this.itemsValidationsRequired.find(i => i.name === field);
        },
        validateFieldsTabeOne() {
            const tabOne = document.getElementById('form_create');
            const selects = tabOne.querySelectorAll('select');
            const inputs = tabOne.querySelectorAll('input');
            const ids = [];
            let valid = true;
            inputs?.forEach(input => {
                ids.push(input.id);
            });
            selects?.forEach(input => {
                ids.push(input.id);
            });
            if (this.itemsValidationsRequired?.length && ids?.length) {
                ids.forEach(id => {
                    let iten = this.itemsValidationsRequired.find(i => i.name === id);
                    if (!iten) return
                    if (!this.client[iten.name]) {
                        document.getElementById(iten.name)?.classList?.add('is-invalid');
                        this.invalid[iten.name] = this.t('GENERAL.MSG.REQUIRED_FIELD');
                        valid = false;
                    } else {
                        this.invalid[iten.name] = false;
                        document.getElementById(iten.name)?.classList?.remove('is-invalid');
                    }
                })
            }
            if (this.type && this.number) {
                if (!this.client?.phones?.length) {
                    this.client.phones = [];
                }
                this.client.phones.push({
                    code_country: this.codeNumber,
                    country_acronym: this.country_acronym,
                    type: this.type,
                    number: this.number,
                    description: this.description
                })
                this.type = null;
                this.number = null;
                this.description = null;
                this.clearDataPhone = true;
            }
            if (this.isRequired('phones')) {
                if (!this.client.phones?.length) {
                    this.invalid.phones = this.t('GENERAL.MSG.REQUIRED_FIELD');
                    valid = false;
                    document.getElementById('phones')?.classList?.add('is-invalid');
                } else {
                    this.invalid.phones = false;
                    document.getElementById('phones')?.classList?.remove('is-invalid');
                }
            }
            if (this.isRequired('description')) {
                if (!this.client.description) {
                    document.getElementById('description')?.classList?.add('is-invalid');
                    this.invalid.description = this.t('GENERAL.MSG.REQUIRED_FIELD');
                    valid = false;
                } else {
                    this.invalid.description = false;
                    document.getElementById('description')?.classList?.remove('is-invalid');
                }
            }
            if (!valid) {
                this.toast.error(this.t('GENERAL.MSG.PLEASE_INFORM_ALL_FIELDS_REQUIRED'));
            }
            return valid;
        },
        checkForm() {
            if (this.itemsValidationsRequired.length) {
                if (!this.validateFieldsTabeOne()) return false;
                let havNotField = false;
                if (this.isRequired('address_id')) {
                    let adr = this.address;
                    if (!adr.cep || !adr.street || !adr.number || !adr.neighborhood) {
                        this.invalid.address = {};
                        this.invalid.address.cep = !adr.cep ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.address.street = !adr.street ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.address.number = !adr.number ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.address.neighborhood = !adr.neighborhood ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        havNotField = true;
                    } else {
                        this.invalid.address = false;
                    }
                }
                if (this.isRequired('comercial_address_id') && this.isPhisicalPerson) {
                    let adr = this.comercialAddress;
                    if (!adr.cep || !adr.street || !adr.number || !adr.neighborhood) {
                        this.invalid.addressComercial = {};
                        this.invalid.addressComercial.cep = !adr.cep ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.addressComercial.street = !adr.street ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.addressComercial.number = !adr.number ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.addressComercial.neighborhood = !adr.neighborhood ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        havNotField = true;
                    } else {
                        this.invalid.addressComercial = false;
                    }
                }
                if (havNotField) {
                    this.toast.error(this.t('GENERAL.MSG.PLEASE_INFORM_ALL_FIELDS_REQUIRED'));
                    return false;
                }
            }

            if (!this.client.email) {
                this.client.email = null;
            } else {
                this.client.email = this.client.email.trim();
            }

            let name = '';
            if (this.isPhisicalPerson) {
                name = this.client.name;
            } else name = this.client.corporate_name;

            if (!name) {
                this.invalid.name = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById("name").classList.add("is-invalid");
                return false;
            } else if (name.length < 2) {
                this.invalid.name = this.t("GENERAL.MSG.MINIMUM_CHARACTER");
                document.getElementById("name").classList.add("is-invalid");
                return false;
            } else if (name.length > 255) {
                this.invalid.name = this.t("GENERAL.MSG.MAXIMUM_CHARACTER");
                document.getElementById("name")?.classList.add("is-invalid");
                return false;
            } else {
                this.invalid.name = false;
                document.getElementById("name")?.classList?.add("is-valid");
            }
            return true;
        },

        sanitizeForm() {
            if (!this.client.email)
                this.client.email = "";
            if (!this.client.rg)
                this.client.rg = null;
            if (!this.client.dispatching_agency)
                this.client.dispatching_agency = null;
            if (!this.client.dispatch_date)
                this.client.dispatch_date = null;
            if (!this.client.nationality)
                this.client.nationality = null;
            if (!this.client.naturalness)
                this.client.naturalness = null;
            if (!this.client.birthday)
                this.client.birthday = null;
            if (!this.client.father_name)
                this.client.father_name = null;
            if (!this.client.mother_name)
                this.client.mother_name = null;
            if (!this.client.profession_id)
                this.client.profession_id = null;
            if (!this.client.trade_name)
                this.client.trade_name = null
            if (!this.client.corporate_name)
                this.client.corporate_name = null
            if (!this.client.description)
                this.client.description = null
        },

        clearForm() {
            this.client = {};
            this.address = {};
            this.phones = {};
            this.file = {};
            this.fileDescription = null;
            this.verifyDrivePermission();
        },
        validateTaxpayer(taxpayer) {
            if (this.isPhisicalPerson) {
                if (taxpayer.length) {
                    if (this.validateCpf(taxpayer)) {
                        this.invalid.taxpayer = false;
                        document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                    } else {
                        this.invalid.taxpayer = 'CPF inválido';
                        document.getElementById("taxpayer").classList.add("is-invalid");
                    }
                } else {
                    this.invalid.taxpayer = false;
                    document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                }
            } else {
                if (taxpayer.length) {
                    if (this.validateCNPJ(taxpayer)) {
                        this.invalid.taxpayer = false;
                        document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                    } else {
                        this.invalid.taxpayer = 'CNPJ inválido';
                        document.getElementById("taxpayer").classList.add("is-invalid");
                    }
                } else {
                    this.invalid.taxpayer = false;
                    document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                }
            }
        },
    }
}
</script>

<style lang="less">
.size {
    width: 200px !important;
}
.size-modal {
    width: 100% !important;
}
.btn-background {
    width: 100%;
    font-size: large !important;
    font-weight: 600 !important;
    background-color: #adadad !important;
}
.modal-dialog {
    overflow-y: initial !important
}
.modal-row2 {
    max-height: 40vh;
    overflow-y: auto;
}
.modal-row {
    max-height: 40vh;
    overflow-y: auto;
}
.modal-row2::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}
.modal-row2::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}
.modal-mask {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-header-color {
    color: white;
    background-color: #0e5e4e !important;
}
.right-content {
    justify-content: flex-end;
}
.swal2-container {
    z-index: 100000000000;
}
.user-avatar {
    max-width: 80px !important;
    max-height: 80px !important;
    border-radius: 50% !important;
    margin-bottom: 1.2rem !important;
}
.avatar-size {
    width: 80px !important;
    height: 80px !important;
}
.cropper-size {
    max-width: 350px !important;
    max-height: 275px !important;
}
.avatar .status {
    top: 62px;
    right: 2px;
    width: 25px;
    height: 25px;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    border: 4px solid #f0f0f0;
}
.camera {
    right: 1;
    bottom: 0;
    outline: 0;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    background-color: #f0f0f0;
}
.align-items {
    align-items: center;
}
.preview {
    border: dashed 1px rgba(255, 255, 255, 0.25);
}
.cropper {
    height: 100%;
    background: #DDD;
}
.hearder-custom {
    color: #333333 !important;
    background: #ffffff !important;
}
.close-custom {
    color: #000 !important;
}
.border-bottom {
    border-bottom: 1px solid #e6ebf1;
}
.border-top {
    background-color: #f2f4f9;
    border-top: 1px solid #e6ebf1;
}
.lg {
    max-width: 800px !important;
}
</style>

<style>

body.authentication {
    display: block;
    align-items: flex-start;
    justify-content: left;
    background: #eff1f5;
}

.modal-row {
    max-height: 100%;
    overflow-y: auto;
}

.modal-row::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}

.modal-row::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}

.secondary {
    border-radius: 3px;
    color: #ffffff !important;
    background-color: #383737;
}

.info-lot {
    border-radius: 7px;
    padding-bottom: 1px;
    padding-top: 6px;
}

.space-between {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.color-grey {
    color: #727272;
}

.font {
    font-size: 0.8rem;
}

.fab {
    position: fixed;
    bottom: 5px;
    right: 10px;
}

.fab button {
    cursor: pointer;
    width: 100%;
    height: 45px;
    border-radius: 20px;
    background-color: #383737;
    border: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .4);
    font-size: 0.9rem;
    color: black;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab button.main {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #0e5e4e !important;
    right: 0;
    bottom: 0;
    z-index: 20;
}

.fab ul {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    padding-right: 5px;
    margin: 0;
    list-style: none;
    z-index: 10;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab ul li {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-bottom: -10%;
    opacity: 0;

    -webkit-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out;
}

.fab ul li label {
    margin-right: 10px;
    white-space: nowrap;
    display: block;
    margin-top: 10px;
    padding: 5px 8px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    border-radius: 3px;
    height: 18px;
    font-size: 16px;
    pointer-events: none;
    opacity: 0;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab button.main:active,
.fab button.main:focus {
    outline: none;
    background-color: #000000;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .5);
}

.fab button.main:active + ul,
.fab button.main:focus + ul {
    bottom: 70px;
}

.fab button.main:active + ul li,
.fab button.main:focus + ul li {
    margin-bottom: 8px;
    opacity: 1;
}

.fab button.main:active + ul li:hover label,
.fab button.main:focus + ul li:hover label {
    opacity: 1;
}
</style>

<style scoped>
.btn-holver {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
}

.bg-btn {
    background-color: #f2f4f9 !important;
    color: black !important;
}

.bg-btn:hover {
    background-color: #0e5e4e !important;
    color: white !important;
}

.custom-checkbox {
    padding-left: 32px !important;
}

.pointer {
    cursor: pointer;
}

@media screen and (max-width: 424px) {
    .mt-m-2 {
        margin-top: 1.1rem !important;
    }
}
</style>

